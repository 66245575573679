import { defineComponent } from 'vue';
import AdminPanelNicknameRequest from '../nickname-request/AdminPanelNicknameRequest.vue';
export default defineComponent({
    components: {
        AdminPanelNicknameRequest,
    },
    props: {
        requests: {
            type: Array,
            required: false,
            default: []
        }
    },
    setup() {
        return {};
    },
});
