import { defineComponent, ref } from 'vue';
import AdminPanelProfile from '@/components/panel/panel-nicknames/AdminPanelProfile.vue';
import { acceptNicknamesRequest, declineNicknamesRequest } from '@/assets/ts/api/nickname';
import { getLoggedAdminId } from '@/assets/ts/helpers/admin';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: {
        CTAButton,
        AdminPanelProfile,
    },
    props: {
        profileId: {
            type: Number,
            required: true,
        },
        requestId: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        let hiddenRequest = ref(false);
        const showNicknames = ref(false);
        const nicknames = ref('');
        const adminId = getLoggedAdminId();
        const acceptRequest = () => acceptNicknamesRequest(props.requestId, nicknames.value, adminId).then(() => hiddenRequest.value = true);
        const showRequestNicknames = () => showNicknames.value = true;
        const cancelEditNicknames = () => showNicknames.value = false;
        const declineRequest = () => declineNicknamesRequest(props.requestId, adminId).then(() => hiddenRequest.value = true);
        return {
            acceptRequest,
            cancelEditNicknames,
            declineRequest,
            showRequestNicknames,
            hiddenRequest,
            showNicknames,
            nicknames
        };
    },
});
