import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        profileId: {
            type: Number,
            required: false,
            default: 0
        },
        nicknames: {
            type: Array,
            required: false,
            default: []
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
});
