import { defineComponent, ref } from 'vue';
import AdminPanelProfile from '@/components/panel/panel-nicknames/AdminPanelProfile.vue';
import AdminPanelNewProfile from '@/components/panel/panel-nicknames/add-profile/AdminPanelNewProfile.vue';
import { getLoggedAdminId } from '@/assets/ts/helpers/admin';
import { updateNicknameRequest } from '@/assets/ts/api/nickname';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
import AdminPanelNicknameRow from '@/components/panel/panel-nicknames/AdminPanelNicknameRow.vue';
import AdminPanelAddNickname from '@/components/panel/panel-nicknames/add-nickname/AdminPanelAddNickname.vue';
export default defineComponent({
    components: {
        AdminPanelAddNickname,
        AdminPanelNicknameRow,
        CTAButton,
        AdminPanelNewProfile,
        AdminPanelProfile
    },
    props: {
        profiles: {
            type: Array,
            required: false,
            default: []
        }
    },
    emits: ['add-nickname', 'add-profile', 'remove-nickname'],
    setup(props, context) {
        const addingNewProfile = ref(false);
        const newNickForProfileId = ref(null);
        const updateNickname = (profileId, nickname, newNickname) => {
            if (newNickname !== '' && nickname.nickname_value !== newNickname) {
                // isLoading.value = true;
                nickname.nickname_value = newNickname;
                const nicknameToUpdate = {
                    admin_id: getLoggedAdminId(),
                    nickname_profile_id: profileId,
                    nickname_value: newNickname,
                };
                updateNicknameRequest(nickname.nickname_id, nicknameToUpdate).finally(() => {
                    // isLoading.value = false;
                });
            }
        };
        const removeNickname = (nicknameId) => context.emit('remove-nickname', nicknameId);
        const addNewNickname = (profile, nickname) => {
            if (nickname !== '') {
                context.emit('add-nickname', {
                    profileId: newNickForProfileId.value,
                    nickname
                });
                newNickForProfileId.value = null;
            }
        };
        const cancelAddingNickname = () => (newNickForProfileId.value = null);
        const runNewNicknameForm = (profileId) => (newNickForProfileId.value = profileId);
        const editNicknames = (profile) => profile._editMode = !profile._editMode;
        const emitAddingProfile = (event) => {
            context.emit('add-profile', event);
        };
        const cancelAddingProfile = () => (addingNewProfile.value = false);
        return {
            addingNewProfile,
            newNickForProfileId,
            addNewNickname,
            cancelAddingNickname,
            cancelAddingProfile,
            editNicknames,
            emitAddingProfile,
            runNewNicknameForm,
            updateNickname,
            removeNickname
        };
    },
});
