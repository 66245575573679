import { defineComponent, ref } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: { CTAButton },
    props: {
        profileId: {
            type: Number,
            required: false,
            default: null
        }
    },
    emits: ['addNickname', 'cancelAddingNickname'],
    setup(props, context) {
        const newNickname = ref('');
        const addNickname = () => context.emit('addNickname', newNickname.value);
        const cancelAddNewNickname = () => {
            newNickname.value = '';
            context.emit('cancelAddingNickname');
        };
        return { addNickname, cancelAddNewNickname, newNickname };
    }
});
