import { defineComponent, onMounted, ref } from 'vue';
import { addNewNicknameRequest, deleteNicknameRequest, getNicknamesAdmin, getNicknamesRequests } from '@/assets/ts/api/nickname';
import AdminPanelLoader from '@/components/panel/panel-components/AdminPanelLoader.vue';
import { getLoggedAdminId } from '@/assets/ts/helpers/admin';
import AdminPanelNicknameRequests from './nickname-requests/AdminPanelNicknameRequests.vue';
import AdminPanelNicknamesManagement from './nickname-management/AdminPanelNicknamesManagement.vue';
export default defineComponent({
    components: {
        AdminPanelNicknamesManagement,
        AdminPanelNicknameRequests,
        AdminPanelLoader,
    },
    setup() {
        const profiles = ref([]);
        const requests = ref([]);
        const adminPanelProfile = ref();
        const isLoading = ref(true);
        const activeCard = ref('');
        const pendingRequestsCount = ref('...');
        onMounted(() => onClickManagement());
        const onRemoveNickname = (nicknameId) => {
            isLoading.value = true;
            deleteNicknameRequest(nicknameId).then(() => onClickManagement()).catch(() => (isLoading.value = false));
        };
        const onAddProfile = (event) => {
            isLoading.value = true;
            const newNicknameObject = {
                nickname_profile_id: event.profile_id,
                nickname_value: event.nickname_value,
                admin_id: getLoggedAdminId(),
            };
            addNewNicknameRequest(newNicknameObject).then(() => onClickManagement()).catch(() => (isLoading.value = false));
        };
        const onAddNickname = (event) => {
            isLoading.value = true;
            const newNicknameObject = {
                nickname_profile_id: event.profileId,
                nickname_value: event.nickname,
                admin_id: getLoggedAdminId(),
            };
            addNewNicknameRequest(newNicknameObject).then(() => onClickManagement()).catch(() => isLoading.value = false);
        };
        const onClickManagement = () => {
            isLoading.value = true;
            activeCard.value = 'management';
            getNicknamesAdmin().then((profilesIncome) => {
                profiles.value = profilesIncome.data;
                pendingRequestsCount.value = profilesIncome.pendingRequestsCount.toString();
            }).finally(() => (isLoading.value = false));
        };
        const onClickRequests = () => {
            isLoading.value = true;
            activeCard.value = 'requests';
            getNicknamesRequests().then((data) => {
                requests.value = data.requests;
                pendingRequestsCount.value = data.pendingRequestsCount.toString();
            }).finally(() => (isLoading.value = false));
        };
        return {
            onAddNickname,
            onAddProfile,
            onClickManagement,
            onClickRequests,
            onRemoveNickname,
            activeCard,
            adminPanelProfile,
            isLoading,
            pendingRequestsCount,
            profiles,
            requests,
        };
    },
});
