import { defineComponent, ref } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: { CTAButton },
    emits: ['addProfile', 'cancelAdding'],
    setup(props, context) {
        const newProfileId = ref('');
        const newNickname = ref('');
        const cancelAdding = () => context.emit('cancelAdding');
        const addProfile = () => context.emit('addProfile', {
            profile_id: newProfileId.value,
            nickname_value: newNickname.value
        });
        function isNumber(value) {
            if (/^[0-9]+$/.test(value.key)) {
                return true;
            }
            else {
                value.preventDefault();
            }
        }
        return { addProfile, cancelAdding, isNumber, newNickname, newProfileId };
    }
});
