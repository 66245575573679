import { defineComponent, ref } from 'vue';
import CTAButton from '@/components/layout/buttons/cta-button/CTAButton.vue';
export default defineComponent({
    components: {
        CTAButton
    },
    props: {
        newNickForProfileId: {
            type: Number,
            required: false,
            default: null
        },
        nicknameValue: {
            type: String,
            required: false,
            default: ''
        },
        profileId: {
            type: Number,
            required: false,
            default: null
        },
    },
    emits: ['changeNickname', 'removeNickname'],
    setup(props, context) {
        const activeEdition = ref(false);
        const enableNewNicknameRow = ref(false);
        const nickname = ref(props.nicknameValue);
        function nicknameChanged() {
            toggleSwitchEdit();
            context.emit('changeNickname', nickname.value);
        }
        const removeNickname = () => context.emit('removeNickname');
        function cancelAndBackValue() {
            nickname.value = props.nicknameValue;
            toggleSwitchEdit();
        }
        const toggleSwitchEdit = () => activeEdition.value = !activeEdition.value;
        return {
            cancelAndBackValue,
            toggleSwitchEdit,
            nicknameChanged,
            removeNickname,
            activeEdition,
            enableNewNicknameRow,
            nickname,
        };
    }
});
